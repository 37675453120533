// Updates.js
import React from "react";
import Box from "@mui/material/Box";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";

const Updates = (props) => {
  const theme = useTheme();

  const notes = [
    "Progress implementing Inventory Tags in Inventory Admin.",
    "Inventory Review and Inventory Assemblies have been merged.",
    "Progress on Inventory Review edit (changing category/group).",
    "Improved styling on Inventory Create.",
    "Progress on creating Inventory Admin.",
    "Wiki Help Button moved to side drawer menu.",
    "Inventory Review and Inventory Search have been merged into a single screen.",
    "Progress on new Purchase Orders, Backorders view in Purchasing.",
    "Progress on wiki and context-sensitive help button.",
    "Inventory API integration nearly complete, pending 'Count'.",
    "Basic Assembly API integration complete.",
    "Basic Inventory Review API integration complete.",
    "Basic Messages app is complete w/ full API integration.",
    "More progress on user settings/preferences, and app initialization config.",
    "Added drawer-menu department selector + indicator benath back button logo.",
    "Revised edit/save form design.",
    "Creating new subviews for Administration (Employee, Customer, etc).",
    "Creating new subviews for Inventory (Assembly, Counts, Review, etc).",
  ];

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          position: "relative",
          borderRadius: "8px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <CasaCloseButton handleClose={props.backButton} />
        <Box
          sx={{
            backgroundColor: theme.palette.customColors.screenHeadingColor, // Update with your desired color
            padding: "10px", // Adjust the padding
            textAlign: "center", // Center-align text
          }}
        >
          <Typography
            variant="h5"
            color="white"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ flex: 1, textAlign: "center" }}>
              <b>Updates</b>
            </div>
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "1em 2em",
            overflow: "auto",
            maxWidth: "1000px",
            margin: "auto",
          }}
        >
          <Typography variant="h3" align="center">
            💾
          </Typography>
          <Typography
            variant="h5"
            component="div"
            align="center"
            sx={{
              fontWeight: "bold",
              textDecoration: "underline",
              marginBottom: "0.1em",
            }}
          >
            Casa Application Updates
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            align="center"
            sx={{
              fontWeight: "bold",
              marginBottom: "1.5em",
            }}
          >
            Current Build: January 24th, 2025
          </Typography>
          <List sx={{ padding: "0 1em" }}>
            {notes.map((note, index) => (
              <ListItem key={index} disableGutters sx={{ padding: "0.3em 0" }}>
                <ListItemText
                  primary={`• ${note}`}
                  primaryTypographyProps={{ variant: "body2" }}
                  sx={{ margin: "0" }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Paper>
      <br />
    </div>
  );
};

export default Updates;
