// InventoryFolder.js
import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";
import { useAPIContext } from "../../../contexts/APIContext";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import axios from "axios";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import { useInventoryContext } from "../../../contexts/InventoryContext";
import { useNavigate } from "react-router-dom";
import CasaConfigureComponent from "../../Resuable/CasaConfigureComponent/CasaConfigureComponent";

const InventoryCreate = (props) => {
  const theme = useTheme();
  const { APIState } = useAPIContext();
  const { userState } = useUserStateContext();
  const { inventoryState, updateInventory } = useInventoryContext();
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.department]);

  async function handleAdd() {
    console.log("code ran");
    if (
      inventoryState.createType === "" ||
      inventoryState.createCategory === "" ||
      inventoryState.createGroup === "" ||
      inventoryState.createDescription === ""
    ) {
      // setIncompleteCreateOpen(true);
      return;
    } else {
      const response = await createNewItem();
      if (Object.entries(response).length > 0) {
        navigate("/inventory/review");
      } else {
        alert("Failed to create item.");
      }
    }
  }

  async function getFilters() {
    /* 
      This is where the app will call the API to populate
      userState with the logged in user's data in UserStateContext.
    */
    try {
      setFiltersLoaded(false);
      updateInventory({
        createType: "",
        createTypeOptions: [],
        createCategory: "",
        createCategoryOptions: [],
        createGroup: "",
        createGroupOptions: [],
      });
      const userAuthObj = await GetUserAndTokens();
      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvCat",
          GetLevel: 0,
          Level1Parm: 0,
          Level2Parm: 0,
          Level3Parm: 0,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });

      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        setFiltersLoaded(true);
        return [];
      } else {
        const dept =
          response.data[0]?.relatedRows[
            userState.getDepartmentIndex(userState.department)
          ]?.LevelID;
        // Finally, retrieve the options for that ID to get the correct data
        const options = response.data.find(
          (filter) => filter.parentId === dept
        );
        updateInventory({
          filters: response.data,
          createTypeOptions: options.relatedRows,
        });
        setFiltersLoaded(true);
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      setFiltersLoaded(true);
      return [];
    }
  }

  async function createNewItem() {
    /* 
      This is where the app will call the API to populate
      userState with the logged in user's data in UserStateContext.
    */
    try {
      const userAuthObj = await GetUserAndTokens();

      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "NewInvItem",
          Level1Parm: parseInt(userState.getDepartmentIndex() + 1),
          Level2Parm: inventoryState.createType.split("-")[1],
          InvCategory: inventoryState.createCategory.split("-")[1],
          InvGroup: inventoryState.createGroup.split("-")[1],
          InvDescription: inventoryState.createDescription,
          InvPart: inventoryState.createPartNum,
          InvCost: inventoryState.createCost,
          InvUnit: inventoryState.createUnit,
          InvQuantity: inventoryState.createQty,
          InvNotes: inventoryState.createNotes,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log("response: ", response);

      if (response.data === null || response.data[0]?.StatusCode === "ERROR") {
        console.log("No results, error, or empty/null response from API.");
        return [];
      } else {
        const id = response.data.InvID;
        let newItemResponse;
        newItemResponse = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "GetInvItem",
            InvMode: 1,
            ItemID: id,
            Level1Parm: 0,
            Level2Parm: 0,
            Level3Parm: 0,
            Level4Parm: 0,
            Keywords: "",
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
        console.log(newItemResponse);
        updateInventory({ createItem: newItemResponse.data[0] });
        return newItemResponse.data[0];
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      return [];
    }
  }

  function handleUpdateInventory(type, value) {
    if (type === "type") {
      updateInventory({
        createType: value,
        createCategory: "",
        createGroup: "",
      });
      // get rows returned from api attached to the json obj w/ parent id
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ createCategoryOptions: results });
      }
      updateInventory({ createGroupOptions: [] });
    } else if (type === "category") {
      updateInventory({
        createCategory: value,
        createGroup: "",
      });
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ createGroupOptions: results });
      } else {
        updateInventory({ createGroupOptions: [] });
      }
    } else if (type === "group") {
      updateInventory({
        createGroup: value,
      });
    }
  }

  return (
    <CasaConfigureComponent
      title="New Item"
      handleAdd={handleAdd}
      closeModalFunction={props.backButton}
    />
  );
};

export default InventoryCreate;
