import "./App.css";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CircularProgress, Container, IconButton } from "@mui/material";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";

import {
  Authenticator,
  View,
  Text,
  Heading,
  Button,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";

import awsExports from "../src/aws-exports";

import { useUserStateContext } from "./contexts/UserStateContext";
import CasaAppBar from "./components/Core/CasaAppBar/CasaAppBar";
import MainContent from "./components/Core/MainContent/MainContent";

import Home from "./components/Screens/Home/Home";
import Estimating from "./components/Screens/Estimating/Estimating";
import Settings from "./components/Screens/Settings/Settings";
import Messages from "./components/Screens/Messages/Messages";
import CasaTimeClock from "./components/Screens/CasaTimeClock/CasaTimeClock";
import Scheduling from "./components/Screens/Scheduling/Scheduling";
import JobRecords from "./components/Screens/JobRecords/JobRecords";
import Reports from "./components/Screens/Reports/Reports";
import Managers from "./components/Screens/Managers/Managers";
import Fleet from "./components/Screens/Fleet/Fleet";

import CasaFootageFinal from "../src/images/CasaFootageFinal.mp4";
import Texas from "../src/images/Texas.mp4";
import Christmas from "../src/images/Christmas.mp4";
import Fireplace from "../src/images/Fireplace.mp4";

import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CasaConfigureComponent from "./components/Resuable/CasaConfigureComponent/CasaConfigureComponent";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import Updates from "./components/Screens/Updates/Updates";
import AllProviders from "./contexts/AllProviders/AllProviders";
import CasaFindInventoryPart from "./components/Screens/Inventory/CasaFindInventoryPart";
import InventoryCreate from "./components/Screens/Inventory/InventoryCreate";
import AssemblySelectTable from "./components/Screens/Inventory/AssemblySelectTable";
import ViewAssemblyTable from "./components/Screens/Inventory/ViewAssemblyTable";
import PartLocator from "./components/Screens/Inventory/PartLocator";
import BulkCount from "./components/Screens/Inventory/BulkCount";
import InventoryReview from "./components/Screens/Inventory/InventoryReview";
import AdministrationFolder from "./components/Screens/Administration/AdministrationFolder";
import ViewEmployee from "./components/Screens/Administration/ViewEmployee";
import TimeclockAdmin from "./components/Screens/Administration/TimeclockAdmin";
import CasaSearchComponent from "./components/Resuable/CasaSearchComponent/CasaSearchComponent";
import InventoryFolder from "./components/Screens/Inventory/InventoryFolder";
import PurchasingFolder from "./components/Screens/Purchasing/PurchasingFolder";
import PurchaseOrders from "./components/Screens/Purchasing/PurchaseOrders";
import Backorders from "./components/Screens/Purchasing/Backorders";
import InventoryAdmin from "./components/Screens/Inventory/InventoryAdmin";
import CasaGPT from "./components/Screens/CasaGPT/CasaGPT";

Amplify.configure(awsExports);

function CasaBGVideoComponent(src, isDark) {
  let location = useLocation();

  // disable/hide video on data entry/important focus screens
  const opacity =
    location.pathname !== "/inventory/ac/filter/results" &&
    location.pathname !== "/inventory/ac/scan" &&
    location.pathname !== "/inventory/plumbing/filter/results" &&
    location.pathname !== "/inventory/plumbing/scan" &&
    location.pathname !== "/administration/employee/information" &&
    location.pathname !== "/administration/employee/permissions" &&
    location.pathname !== "/administration/employee/reports" &&
    location.pathname !== "/administration/employee/apps"
      ? 1
      : 0;

  // Show/hide background video on screens
  if (true) {
    return (
      <video
        autoPlay
        muted
        loop
        playsInline
        webkit-playsinline="true"
        key={src}
        style={{
          position: "fixed",
          width: "calc(103%)",
          height: "calc(110% + 10px)",
          objectFit: "cover",
          zIndex: "-1",
          top: "-2px",
          left: "-2%",
          filter: isDark
            ? "brightness(20%) blur(7px)"
            : "brightness(40%) blur(4px)",
          opacity: opacity,
        }}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  } else {
    //do nothing
  }
}

function App() {
  const { userState, updateUserState } = useUserStateContext();
  const screenName =
    userState.department === "Air Conditioning" ? "AC" : userState.department;
  let location = useLocation();
  let url = location.pathname;
  const navigate = useNavigate();
  const theme = userState.colorTheme;

  // auth component overrides
  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.xl}>
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="73.5pt"
            height="73.5pt"
            viewBox="0 0 147.000000 147.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,147.000000) scale(0.100000,-0.100000)"
              fill="white"
              stroke="white"
            >
              <path d="M 515 1292 l -210 -157 l 1 68 l 1 67 l -56 0 l -58 1 l 0 -119 l 0 -119 l -60 -43 l -59 -42 l 0 -54 l -2 -51 c 6 0 157 111 336 246 c 180 135 332 248 338 250 c 7 3 61 -33 121 -79 l 569 -419 l 6 52 c 0 36 13 36 -22 67 c -13 11 -135 105 -273 209 c -137 103 -281 212 -319 241 c -38 29 -72 53 -75 53 z" />
              <path d="M 162 1330 l 0 -30 l 85 0 l 85 0 l 0 30 l 0 30 l -85 0 l -85 0 l 0 -30 z" />
              <path d="M545 1114 c-50 -36 -94 -72 -98 -80 -4 -7 -6 -208 -5 -446 l3 -433 80 0 80 0 2 315 c1 173 6 316 11 318 5 1 19 -55 31 -125 13 -71 27 -150 33 -176 l10 -48 51 3 51 3 27 135 c 14 74 30 152 33 173 4 20 11 37 16 37 6 0 10 -123 10 -320 l0 -320 80 0 80 0 0 444 0 444 -96 72 c -53 39 -98 69 -101 67 -6 -6 -46 -202 -68 -337 -16 -96 -28 -131 -39 -119 -2 2 -23 106 -47 232 -24 125 -46 227 -49 227 -3 0 -46 -30 -95 -66z" />
              <path d="M1215 841 c-51 -13 -72 -29 -94 -74 -23 -48 -29 -146 -10 -194 5 -15 44 -63 86 -105 73 -73 76 -79 81 -130 3 -41 0 -57 -12 -67 -29 -24 -46 -6 -46 49 l0 50 -61 0 -62 0 5 -70 c8 -109 50 -154 142 -154 98 0 150 71 150 203 0 97 -15 127 -99 211 -69 69 -75 78 -75 116 0 50 20 73 44 49 9 -9 16 -32 16 -51 l0 -34 55 0 c66 0 71 7 55 84 -9 42 -19 62 -46 85 -19 17 -43 31 -54 31 -10 0 -24 2 -32 4 -7 2 -26 1 -43 -3z" />
              <path d="M150 822 c-19 -9 -43 -33 -55 -52 -19 -33 -20 -52 -20 -275 0 -267 4 -286 68 -325 41 -25 120 -26 167 -2 51 26 71 69 78 167 l5 85 -62 0 -61 0 0 -66 c0 -72 -12 -104 -38 -104 -39 0 -42 16 -42 245 0 213 1 223 21 241 21 19 21 18 40 -5 13 -16 19 -39 19 -77 l0 -54 61 0 62 0 -6 75 c-6 86 -27 128 -73 150 -43 20 -121 19 -164 -3z" />
              <path d="M 62 36 l 670 0 l 670 0 l 0 40 l 0 40 l -668 3 l -672 0 z" />
            </g>
          </svg>
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color="white">&copy; 2024 Casa Mechanical Services.</Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <>
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                updateUserState({
                  ...userState,
                  audioEnabled: !userState.audioEnabled,
                });
              }}
              sx={{
                position: "fixed",
                top: "15px",
                left: "15px",
                zIndex: 0,
                color: "white",
                border: "2px solid",
                backgroundColor: "transparent",
              }}
            >
              {userState.audioEnabled ? <VolumeUpIcon /> : <VolumeOffIcon />}
            </IconButton>
            <video
              autoPlay
              muted={!userState.audioEnabled}
              loop
              playsInline
              webkit-playsinline="true"
              style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: "-1",
                top: "0",
                left: "0",
                filter: "brightness(70%)",
              }}
            >
              <source
                src={require("./images/CasaFootageFinal.mp4")}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <center>
              <Heading
                padding={`${tokens.space.medium} 0 0 0`}
                level={3}
                sx={{ textAlign: "center", margin: "auto" }}
              >
                Sign in to Casa
              </Heading>
            </center>
          </>
        );
      },
      Footer() {
        const { toForgotPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toForgotPassword}
              size="small"
              variation="link"
            >
              Forgot Casa Password?
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <>
            <IconButton
              onClick={() =>
                updateUserState({
                  ...userState,
                  audioEnabled: !userState.audioEnabled,
                })
              }
              sx={{
                position: "fixed",
                top: "15px",
                left: "15px",
                zIndex: 0,
                color: "white",
                border: "2px solid",
                backgroundColor: "transparent",
              }}
            >
              {userState.audioEnabled ? <VolumeUpIcon /> : <VolumeOffIcon />}
            </IconButton>
            <video
              autoPlay
              muted={!userState.audioEnabled}
              loop
              playsInline
              webkit-playsinline="true"
              style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: "-1",
                top: "0",
                left: "0",
                filter: "brightness(70%)",
              }}
            >
              <source src={require("./images/welcome.mp4")} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Heading
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              level={3}
            >
              New Employee Registration
            </Heading>
          </>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Already have a Casa account?
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Confirm Registration
          </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    SetupTotp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Set up Google Auth 2FA
          </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 0`} level={3}>
            Mobile 2FA Code
          </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <>
            <Heading padding={`${tokens.space.xl} 0 0 0`} level={3}>
              Reset Casa Password
            </Heading>
            <Text>** PLEASE READ BEFORE SUBMITTING **</Text>
            <Text>
              If you have lost access to your mobile device and can no longer
              use 2FA, contact your manager ASAP.
            </Text>
            <Text>
              If you only need to reset your Casa account password, clicking
              'Send Code' will email you a verification code to enter on the
              following screen.
            </Text>
          </>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <>
            <Heading padding={`${tokens.space.xl} 0 0 0`} level={3}>
              Reset Password
            </Heading>
            <Text>
              Check your Casa email for a verification code, and enter it below.
            </Text>
          </>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        label: "Casa ID:",
        placeholder: "Enter Casa ID",
        isRequired: true,
      },
      password: {
        label: "Password:",
        placeholder: "Enter Password",
        isRequired: true,
      },
    },
    signUp: {
      username: {
        label: "Casa ID:",
        placeholder: "Enter Casa ID",
        order: 1,
        isRequired: true,
      },
      email: {
        label: "Casa Email:",
        placeholder: "Enter Casa Email",
        order: 2,
        isRequired: true,
      },
      password: {
        label: "Password:",
        placeholder: "Enter Password",
        isRequired: true,
        order: 3,
      },
      confirm_password: {
        label: "Confirm Password:",
        placeholder: "Enter Password Again",
        isRequired: true,
        order: 4,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "Enter your Password:",
        isRequired: true,
      },
    },
    forgotPassword: {
      username: {
        label: "Casa ID:",
        placeholder: "Enter your Casa ID",
        isRequired: true,
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        label: "Email Confirmation Code:",
        placeholder: "Enter Email Confirmation Code",
        isRequired: true,
      },
      confirm_password: {
        placeholder: "Enter New Password Again",
        isRequired: true,
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: "Casa Mechanical Services",
      },
      confirmation_code: {
        label: "2FA Code from Mobile App:",
        placeholder: "Enter 2FA Code",
        isRequired: true,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: "2FA Code from Mobile App:",
        placeholder: "Enter 2FA Code",
        isRequired: true,
      },
    },
  };

  const getParentURL = () => {
    // Trim any trailing slashes
    const newUrl = url.replace(/\/+$/, "");

    // Split the URL by slashes
    const parts = newUrl.split("/");

    // If the URL is root or has no parts, return root
    if (parts.length <= 1) {
      return "/";
    }

    // Remove the last part to get the parent directory
    parts.pop();

    // Join the remaining parts back together
    const parentURL = parts.join("/");

    // Ensure the result starts with a slash and return
    const ret = parentURL.startsWith("/") ? parentURL : "/" + parentURL;
    return ret;
  };

  function goUpURL() {
    navigate(getParentURL());
  }

  // this is jank but is here bc stuff got changed
  function changeDepartment(newDepartment) {
    updateUserState({ department: newDepartment });
  }

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {userState.name === "-" && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(50, 50, 50, 1)",
            zIndex: 2000, // Ensure it's above the rest of the content
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            size={80}
            sx={{
              color: "#fff",
            }}
          />
        </div>
      )}
      {(() => {
        switch (userState.bgVideoSrc) {
          case "Casa":
            return CasaBGVideoComponent(CasaFootageFinal, false);
          case "Longhorns":
            return CasaBGVideoComponent(Texas, true);
          case "Christmas":
            return CasaBGVideoComponent(Christmas, true);
          case "Fireplace":
            return CasaBGVideoComponent(Fireplace, true);
          default:
            // No video
            break;
        }
      })()}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          maxWidth="xl"
          sx={{ minHeight: "100vh", overflow: "hidden" }}
        >
          <AllProviders>
            <CasaAppBar
              department={userState.department}
              setDepartment={changeDepartment}
            />
            <MainContent>
              {/* Providers */}
              <TransitionGroup component={null}>
                <CSSTransition
                  key={location.key}
                  classNames="fade"
                  timeout={300}
                  unmountOnExit
                >
                  <Routes location={location}>
                    {/* Main Screens */}
                    <Route path="/" element={<Home />} />
                    <Route
                      path="/settings"
                      element={<Settings backButton={goUpURL} />}
                    />
                    <Route
                      path="/updates"
                      element={<Updates backButton={goUpURL} />}
                    />
                    <Route
                      path="/estimating"
                      element={<Estimating backButton={goUpURL} />}
                    />
                    <Route
                      path="/scheduling"
                      element={<Scheduling backButton={goUpURL} />}
                    />

                    {/* Inventory
                    <Route path="/inventory" element={<InventoryFolder />} /> */}

                    <Route
                      path="/inventory/*"
                      element={
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <InventoryFolder
                                title={screenName + " Inventory"}
                              />
                            }
                          />
                          <Route
                            path="search"
                            element={
                              <CasaFindInventoryPart backButton={goUpURL} />
                            }
                          />
                          <Route
                            path="create"
                            element={
                              <InventoryCreate
                                backButton={goUpURL}
                                name={screenName}
                              />
                            }
                          />
                          <Route
                            path="assembly"
                            element={
                              <AssemblySelectTable
                                name={screenName}
                                title="Search Assemblies"
                                backButton={goUpURL}
                              />
                            }
                          />
                          <Route
                            path="assembly/view"
                            element={
                              <ViewAssemblyTable
                                name={screenName}
                                title="Assembly Results"
                                backButton={goUpURL}
                              />
                            }
                          />
                          <Route
                            path="assembly/view/add"
                            element={
                              <PartLocator
                                name={screenName}
                                backButton={goUpURL}
                              />
                            }
                          />
                          <Route
                            path="count"
                            element={
                              <BulkCount
                                name={screenName}
                                title={"Bulk Count"}
                                backButton={goUpURL}
                              />
                            }
                          />
                          <Route
                            path="review"
                            element={<InventoryReview backButton={goUpURL} />}
                          />
                          <Route
                            path="admin"
                            element={
                              <InventoryAdmin
                                backButton={goUpURL}
                                title={screenName}
                              />
                            }
                          />
                        </Routes>
                      }
                    />

                    {/* Administration */}
                    <Route
                      path="/administration/*"
                      element={
                        <Routes>
                          <Route path="/" element={<AdministrationFolder />} />
                          {/* Employees */}
                          <Route
                            path="/search-employees"
                            element={
                              <CasaSearchComponent
                                type="employee"
                                title="Find Employee"
                                subtitle="Search Employee List"
                                placeholder="Employee Name"
                                buttonText="View Employee"
                                onClickURL="/administration/search-employees/view"
                                backButton={goUpURL}
                              />
                            }
                          />
                          <Route
                            path="/search-employees/view"
                            element={<ViewEmployee backButton={goUpURL} />}
                          />
                          <Route
                            path="/search-employees/view/information"
                            element={
                              <CasaConfigureComponent
                                title="Information"
                                closeModalFunction={goUpURL}
                                editEnabled={true}
                              />
                            }
                          />
                          <Route
                            path="/search-employees/view/permissions"
                            element={
                              <CasaConfigureComponent
                                title="Permissions"
                                closeModalFunction={goUpURL}
                                editEnabled={true}
                              />
                            }
                          />
                          <Route
                            path="/search-employees/view/reports"
                            element={
                              <CasaConfigureComponent
                                title="Reports"
                                closeModalFunction={goUpURL}
                                editEnabled={false}
                              />
                            }
                          />
                          <Route
                            path="/search-employees/view/apps"
                            element={
                              <CasaConfigureComponent
                                title="Apps"
                                closeModalFunction={goUpURL}
                                editEnabled={false}
                              />
                            }
                          />
                          {/* Customers */}
                          <Route
                            path="/search-customers"
                            element={
                              <CasaSearchComponent
                                type="customer"
                                title="Find Customer"
                                subtitle="Search Customer List"
                                placeholder="Customer Name"
                                buttonText="View Customer"
                                onClickURL="/administration/search-customers/view"
                                backButton={goUpURL}
                              />
                            }
                          />
                          <Route
                            path="/search-customers/view"
                            element={
                              <CasaConfigureComponent
                                title="Customer"
                                closeModalFunction={goUpURL}
                                editEnabled={true}
                              />
                            }
                          />
                          <Route
                            path="/timeclock"
                            element={
                              <TimeclockAdmin
                                name="Add Clock Entry"
                                backButton={goUpURL}
                              />
                            }
                          />
                        </Routes>
                      }
                    />

                    <Route
                      path="/purchasing/*"
                      element={
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <PurchasingFolder
                                title="Purchasing"
                                backButton={goUpURL}
                              />
                            }
                          />
                          <Route
                            path="/orders"
                            element={<PurchaseOrders backButton={goUpURL} />}
                          />
                          <Route
                            path="/backorders"
                            element={<Backorders backButton={goUpURL} />}
                          />
                        </Routes>
                      }
                    />

                    <Route
                      path="/jobrecords"
                      element={<JobRecords backButton={goUpURL} />}
                    />
                    <Route
                      path="/reports"
                      element={<Reports backButton={goUpURL} />}
                    />
                    <Route
                      path="/timeclock"
                      element={<CasaTimeClock backButton={goUpURL} />}
                    />
                    <Route
                      path="/managers"
                      element={<Managers backButton={goUpURL} />}
                    />
                    <Route
                      path="/fleet"
                      element={<Fleet backButton={goUpURL} />}
                    />

                    <Route
                      path="/casagpt"
                      element={<CasaGPT backButton={goUpURL} />}
                    />
                    {/* Sub-views */}
                    <Route
                      path="/messages"
                      element={<Messages backButton={goUpURL} />}
                    />
                  </Routes>
                </CSSTransition>
              </TransitionGroup>
            </MainContent>
          </AllProviders>
        </Container>
      </ThemeProvider>
    </Authenticator>
  );
}

export default App;
