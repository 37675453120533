// AssemblySelectTable.js
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import { useTheme } from "@mui/material/styles";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import CasaSnackbar from "../../Resuable/CasaSnackbar/CasaSnackbar";
import "./Inventory.css"; // Import CSS for transitions
import { useNavigate } from "react-router-dom";
import { useInventoryContext } from "../../../contexts/InventoryContext";
import { useAPIContext } from "../../../contexts/APIContext";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import axios from "axios";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";
import CasaIdleTableView from "../../Resuable/CasaIdleTableView/CasaIdleTableView";
import CasaDetailDashComponent from "../../Resuable/CasaDetailDashComponent/CasaDetailDashComponent";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "InvDescription",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
          {/* <span>-</span> */}
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              "&:hover": {
                color: "#fff", // Change this to the desired hover color
              },
              "&:focus": {
                color: "#fff",
                fontWeight: "bold",
              },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                "&.MuiTableSortLabel-root": {
                  color: "white",
                },
                "&.MuiTableSortLabel-root:hover": {
                  color: "white",
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
                "&.Mui-active": {
                  color: "white",
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
                "& .MuiTableSortLabel-icon": {
                  color: "white !important",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function ThickInventoryHeader({
  name,
  title,
  rows,
  setRows,
  handleAddMode,
  setFilteredRows,
  setPage,
  setIsLoading,
  backButton,
}) {
  const selectedColumn = "InvDescription";
  const { userState } = useUserStateContext();
  const { inventoryState, updateInventory } = useInventoryContext();
  const { APIState } = useAPIContext();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // Determine screen size

  const [filtersLoaded, setFiltersLoaded] = React.useState(false);

  async function getStages() {
    try {
      const userAuthObj = await GetUserAndTokens();

      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvStages",
          Level1Parm: parseInt(
            userState.getDepartmentIndex(userState.department) + 1
          ),
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });

      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        return [];
      } else {
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      return [];
    }
  }

  async function getFilters() {
    try {
      const userAuthObj = await GetUserAndTokens();

      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvCat",
          GetLevel: 0,
          Level1Parm: 0,
          Level2Parm: 0,
          Level3Parm: 0,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });

      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        return [];
      } else {
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      return [];
    }
  }

  const getFilterOptions = async () => {
    // First, you need to retrieve data
    if (
      !inventoryState.assemblyGroup ||
      inventoryState.assemblyGroup === "" ||
      inventoryState.assemblyDepartmentIndex !==
        userState.getDepartmentIndex(userState.department)
    ) {
      setIsLoading(true);
      setRows([]);

      setFiltersLoaded(false);
      updateInventory({
        assemblyType: "",
        assemblyCategory: "",
        assemblyGroup: "",
        assemblyCategoryOptions: [],
        assemblyGroupOptions: [],
        assemblyDepartmentIndex: userState.getDepartmentIndex(
          userState.department
        ),
      });
      const filterData = await getFilters();
      const stageOptions = await getStages();
      // Next, get the ID of the department you're looking for
      const dept =
        filterData[0]?.relatedRows[
          userState.getDepartmentIndex(userState.department)
        ]?.LevelID;
      // Finally, retrieve the filterOptions for that ID to get the correct data
      const filterOptions = filterData.find(
        (filter) => filter.parentId === dept
      );
      updateInventory({
        filters: filterData,
        assemblyType: filterOptions.relatedRows[0].LevelID,
        stageOptions: stageOptions,
      });
      const results = filterData.find(
        (filter) => filter.parentId === filterOptions.relatedRows[0].LevelID
      )?.relatedRows;
      if (results) {
        updateInventory({ assemblyCategoryOptions: results });
      }
      updateInventory({ assemblyGroupOptions: [] });

      setFiltersLoaded(true);
      setIsLoading(false);
    } else {
      setFiltersLoaded(true);
      getDataFromFilters(inventoryState.assemblyGroup);
    }
  };

  async function searchParts(value) {
    if (inventoryState.assemblyType === "") {
      alert("Please select a type.");
      return;
    } else if (inventoryState.assemblyCategory === "" || !value) {
      alert("Please select a category.");
      return;
    }
    const data = await getDataFromFilters(value);
    setRows(data);
  }

  async function getDataFromFilters(group) {
    try {
      setIsLoading(true);
      const userAuthObj = await GetUserAndTokens();
      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvItem",
          InvMode: 2,
          Level1Parm: parseInt(
            userState.getDepartmentIndex(userState.department) + 1
          ),
          Level2Parm: 1, //inventoryState.assemblyType[0]
          Level3Parm: inventoryState.assemblyCategory.split("-")[1],
          Level4Parm: group.split("-")[1],
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        setIsLoading(false);
        return [];
      } else {
        setRows(response.data);
        setIsLoading(false);
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      setIsLoading(false);
      return [];
    }
  }

  React.useEffect(() => {
    getFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.department]);

  React.useEffect(() => {
    const filteredRows = rows.filter((row) => {
      return row[selectedColumn].toString().toLowerCase();
    });
    setPage(0);
    setFilteredRows(filteredRows);
  }, [selectedColumn, rows, setFilteredRows, setPage]);

  function handleUpdateInventory(type, value) {
    if (type === "type") {
      updateInventory({
        assemblyType: value,
        assemblyCategory: "",
        assemblyGroup: "",
      });
      setRows([]);
      // get rows returned from api attached to the json obj w/ parent id
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ assemblyCategoryOptions: results });
      }
      updateInventory({ assemblyGroupOptions: [] });
    } else if (type === "category") {
      setRows([]);
      updateInventory({
        assemblyCategory: value,
        assemblyGroup: "",
      });
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ assemblyGroupOptions: results });
      } else {
        updateInventory({ assemblyGroupOptions: [] });
      }
    } else if (type === "group") {
      updateInventory({
        assemblyGroup: value,
      });
    }
  }

  function handleBack() {
    updateInventory({ assembly: null });
    backButton();
  }

  return (
    <Paper sx={{ width: "100%", mb: 2, position: "relative", padding: "2px" }}>
      <CasaCloseButton handleClose={handleBack} />
      <div
        style={{
          textAlign: isLargeScreen ? "left" : "center", // Align content based on screen size
          display: isLargeScreen ? "flex" : "block", // Use flexbox for alignment
          alignItems: "center", // Align items vertically
          justifyContent: isLargeScreen ? "space-between" : "center", // Adjust justify content based on screen size
          padding: "1vh 1vw", // Uniform padding
        }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",
            textDecoration: "underline",
            padding: "1vh",
          }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          <b>{title}</b>
        </Typography>
        {/* {isLargeScreen && ( // Render filter and search field only on large screens */}
        <>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              marginY: "8px",
              marginX: { xs: "0px", sm: "3px", md: "4px" },
            }}
          >
            <InputLabel id="category-label">
              {!filtersLoaded ? "Loading..." : "Category"}
            </InputLabel>
            <Select
              labelId="category-label"
              id="category"
              value={inventoryState.assemblyCategory}
              disabled={!filtersLoaded}
              onChange={(event) => {
                handleUpdateInventory("category", event.target.value);
              }}
              label={!filtersLoaded ? "Loading..." : "Category"}
            >
              {inventoryState.assemblyCategoryOptions.map((option) => (
                <MenuItem key={option.LevelID} value={option.LevelID}>
                  {option.LevelDesc}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              marginY: "8px",
              marginX: { xs: "0px", sm: "3px", md: "4px" },
            }}
          >
            {/* Add the InputLabel component */}
            <InputLabel id="group-label">
              {!filtersLoaded ? "Loading..." : "Group"}
            </InputLabel>

            <Select
              labelId="group-label"
              id="group"
              value={inventoryState.assemblyGroup}
              disabled={
                !filtersLoaded ||
                inventoryState.assemblyGroupOptions.length === 0 ||
                inventoryState.assemblyCategory === ""
              }
              onChange={(event) => {
                handleUpdateInventory("group", event.target.value);
                if (event.target.value) {
                  searchParts(event.target.value);
                }
              }}
              label={!filtersLoaded ? "Loading..." : "Group"}
            >
              {inventoryState.assemblyGroupOptions.map((option) => (
                <MenuItem key={option.LevelID} value={option.LevelID}>
                  {option.LevelDesc}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
        {isLargeScreen ? (
          <>
            <br />
            <br />
          </>
        ) : (
          <></>
        )}
        {/* )} */}
      </div>
    </Paper>
  );
}

export default function AssemblySelectTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("description");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [addMode, setAddMode] = React.useState(false);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const { inventoryState, updateInventory } = useInventoryContext();
  const [isLoading, setIsLoading] = React.useState();
  const { userState } = useUserStateContext();

  function handleAddMode() {
    setAddMode(!addMode);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredRows, order, orderBy, page, rowsPerPage]
  );

  const navigate = useNavigate();

  function viewAssembly(row) {
    updateInventory({ assembly: row });
    navigate("/inventory/assembly/view");
  }

  const tableContainerRef = React.useRef(null);
  React.useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [page]);

  function mainTable(tableContainerRef) {
    return (
      <>
        <ThickInventoryHeader
          name={props.name}
          title={props.title}
          rows={rows}
          setRows={setRows}
          handleAddMode={handleAddMode}
          setFilteredRows={setFilteredRows}
          setPage={setPage}
          setIsLoading={setIsLoading}
          backButton={props.backButton}
        />

        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer
              ref={tableContainerRef}
              sx={{ minHeight: "60vh", maxHeight: "60vh" }}
            >
              {!isLoading && rows.length > 0 ? (
                <Table
                  stickyHeader
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => updateInventory({ assembly: row })}
                        >
                          <TableCell>
                            <center>
                              {/* <InfoOutlinedIcon sx={{ color: "#555" }} /> */}
                            </center>
                          </TableCell>
                          <TableCell
                            style={{
                              minWidth: "235px",
                            }}
                          >
                            {row.InvDescription}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <CasaIdleTableView
                  isLoading={isLoading}
                  noResultText="No results. Use the dropdowns to select a category and group."
                />
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </>
    );
  }

  const getKey = () => {
    if (inventoryState.assembly) {
      return 1;
    }
    return 0;
  };

  const compArr = [
    <div key="mainTable">{mainTable(tableContainerRef)}</div>,
    <div key="infoDash">
      <CasaDetailDashComponent
        title="View Assembly"
        department={userState.department}
        dashtype="inventory-assemblies"
        inventoryState={inventoryState}
        closeModalFunction={() => {
          updateInventory({ assembly: null });
        }}
      />
    </div>,
  ];

  return (
    <div>
      <CasaSnackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={"Saved successfully."}
      />
      <SwitchTransition>
        <CSSTransition
          key={getKey()}
          timeout={150}
          classNames="fade-inventory"
          unmountOnExit
        >
          {compArr[getKey()]}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}
