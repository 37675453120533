// CasaTimeClock.js
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import { CircularProgress, Grid, useTheme } from "@mui/material";
import axios from "axios";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import { useAPIContext } from "../../../contexts/APIContext";

const CasaTimeClock = (props) => {
  const theme = useTheme();
  const { userState, updateUserState } = useUserStateContext();
  const { APIState } = useAPIContext();

  // API functions
  async function handleClockOperation(operation) {
    /* 
      This is where the app will call the API to populate
      userState with the logged in user's data in UserStateContext.
    */
    try {
      const userAuthObj = await GetUserAndTokens();

      if (operation === "clockin") {
        const response = await axios.get(APIState.SysAPI, {
          params: {
            SysFunc: "SetClockIn",
            SAFlag: 0,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
        setClockDataLoaded(true);
        return response.data;
      } else if (operation === "clockout") {
        const response = await axios.get(APIState.SysAPI, {
          params: {
            SysFunc: "SetClockOut",
            TimeLunch: 0,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
        setClockDataLoaded(true);
        return response.data;
      } else {
        const response = await axios.get(APIState.SysAPI, {
          params: {
            SysFunc: "GetClockStatus",
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
        setClockDataLoaded(true);
        return response.data;
      }
    } catch (e) {
      console.error("Timeclock API error.");
      return [];
    }
  }

  const [clockInTime, setClockInTime] = useState();
  const [clockOutTime, setClockOutTime] = useState();
  const [isClockedIn, setIsClockedIn] = useState();
  const [clockDataLoaded, setClockDataLoaded] = useState();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      const data = await handleClockOperation("status");

      setClockInTime(data.TimeIn);
      setClockOutTime(data.TimeOut);

      if (data.StatusMessage === "Awaiting new clock-in") {
        setIsClockedIn(false);
      } else if (data.StatusMessage === "Awaiting clock-out") {
        setIsClockedIn(true);
      } else if (
        data.StatusMessage === "Previous clock-in was over 24 hours ago"
      ) {
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 20);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const secondsAngle =
    (360 -
      ((currentTime.getSeconds() * 1000 + currentTime.getMilliseconds()) /
        60000) *
        360 -
      90) %
    360;
  const minutesAngle =
    (360 -
      ((currentTime.getMinutes() + currentTime.getSeconds() / 60) / 60) * 360 -
      90) %
    360;
  const hoursAngle =
    (360 -
      (((currentTime.getHours() % 12) + currentTime.getMinutes() / 60) / 12) *
        360 -
      90) %
    360;

  const getTimeGreeting = () => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const str =
      (hours % 12 === 0 ? 12 : hours % 12) +
      (minutes < 10 ? ":0" + minutes : ":" + minutes);
    return hours < 12 ? str + " AM" : str + " PM";
  };

  function handleClockInOut() {
    setClockDataLoaded(false);

    let hr = currentTime.getHours() % 12;
    if (hr === 0) {
      hr = 12;
    }
    let str = hr + ":" + currentTime.getMinutes().toString().padStart(2, "0");
    str = currentTime.getHours() >= 12 ? str + " PM" : str + " AM";

    if (!isClockedIn) {
      handleClockOperation("clockin");
      setClockInTime(str);
      setIsClockedIn(true);
    } else {
      handleClockOperation("clockout");
      setClockOutTime(str);
      setIsClockedIn(false);
    }

    updateUserState({ clockedIn: !userState.clockedIn });
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={2} sx={{ height: "100%", marginTop: "1vh" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "85%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <svg
              viewBox="25 25 150 150"
              preserveAspectRatio="xMidYMid meet"
              style={{ height: "100%", width: "100%" }}
            >
              <rect
                x="25"
                y="25"
                width="150"
                height="150"
                fill="#fff"
                stroke="#000"
                strokeWidth="4"
              />
              <svg
                x="87"
                y="45"
                width="25"
                height="25"
                viewBox="0 0 147 147"
                preserveAspectRatio="xMidYMid slice"
              >
                <g transform="scale(1.5)">{userState.colorLogo}</g>
              </svg>
              <line
                x1="100"
                y1="100"
                x2={100 + 60 * Math.sin((minutesAngle - 90) * (Math.PI / 180))}
                y2={100 + 60 * Math.cos((minutesAngle - 90) * (Math.PI / 180))}
                stroke="#222"
                strokeWidth="3"
              />
              <line
                x1="100"
                y1="100"
                x2={100 + 40 * Math.sin((hoursAngle - 90) * (Math.PI / 180))}
                y2={100 + 50 * Math.cos((hoursAngle - 90) * (Math.PI / 180))}
                stroke="#222"
                strokeWidth="3"
              />
              <line
                x1="100"
                y1="100"
                x2={100 + 70 * Math.sin((secondsAngle - 90) * (Math.PI / 180))}
                y2={100 + 70 * Math.cos((secondsAngle - 90) * (Math.PI / 180))}
                stroke={theme.palette.customColors.timeClockSecondHandColor}
                strokeWidth="2"
              />
              <circle
                cx="100"
                cy="100"
                r="3"
                fill={theme.palette.customColors.timeClockSecondHandColor}
              />
            </svg>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            {clockDataLoaded ? (
              <>
                <Box
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "4em",
                      color: "#ffffff",
                      fontWeight: "bold",
                      fontFamily: "KodeMono",
                      margin: -15,
                    }}
                  >
                    {getTimeGreeting()}
                  </span>
                  <span
                    style={{ fontSize: "1.5rem", color: "#ffffff", margin: 0 }}
                  >
                    {isClockedIn
                      ? `Clocked In: ${clockInTime}.`
                      : clockOutTime !== null
                      ? `Clocked Out: ${clockOutTime}.`
                      : `Timeclock error, contact your manager.`}
                  </span>
                </Box>
                <Box
                  sx={{
                    paddingTop: "1vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "2",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClockInOut}
                    size="large"
                  >
                    {isClockedIn ? "Clock Out" : "Clock In"}
                  </Button>
                </Box>
              </>
            ) : (
              <div>
                <CircularProgress
                  sx={{
                    color: theme.palette.customColors.circleButtonColorHover,
                  }}
                  size={60}
                />
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CasaTimeClock;
