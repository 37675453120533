import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useTheme } from "@mui/material";
import { useCasaWindowContext } from "../../../contexts/CasaWindowContext";

import axios from "axios";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import {
  darkModeTheme,
  lightModeTheme,
  testMagentaTheme,
  texasModeTheme,
} from "../../Themes/Themes";
import {
  casaLogo,
  casaLogoColor,
  testLogo,
  texasLogo,
  texasLogoColor,
} from "../../Themes/Logos";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import { useAPIContext } from "../../../contexts/APIContext";
import { useLocation } from "react-router-dom";

function getPathFromUrl(url) {
  const urlObj = new URL(url);
  return urlObj.pathname + urlObj.search + urlObj.hash;
}

function calculateHeight(containerHeight, childHeight) {
  if (childHeight >= containerHeight - 250) {
    // for children taller than screen (i.e. scrollable)
    return 120;
  } else if (containerHeight > childHeight + 450) {
    // for small children
    return (containerHeight - childHeight) / 2 - 50;
  } else {
    // for medium size children
    return (containerHeight - childHeight) / 2;
  }
}

function getWikiDocsLink(str) {
  let url = "https://master.d3jvk20l3o8j3x.amplifyapp.com";
  // handle root
  if (str === "/") {
    return url + "";
  }
  // handle category folders
  if (str === "/inventory") {
    return url + "/docs/category" + str;
  }
  // handle individual screens
  return url + "/docs" + str;
}

const MainContent = ({ children, ...props }) => {
  const theme = useTheme();
  const { casaWindowState } = useCasaWindowContext();
  const { updateUserState } = useUserStateContext();
  const { APIState } = useAPIContext();
  const childRef = useRef(null);
  const [childHeight, setChildHeight] = useState(0);
  const heightOffset = calculateHeight(casaWindowState.height, childHeight);
  const [wikiLink, setWikiLink] = useState("");
  const location = useLocation();

  useEffect(() => {
    const currentPath = getPathFromUrl(window.location);
    const link = getWikiDocsLink(currentPath);
    setWikiLink(link);
  }, [location.pathname]);

  const childrenWithProps = React.Children.map(children, (child) => {
    // Pass the props to each child
    return React.cloneElement(child, { ...props });
  });

  async function setupCasa() {
    const userAuthObj = await GetUserAndTokens();
    try {
      const response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "GetSettings",
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      setupUserSettings(response.data, userAuthObj.user, userAuthObj.idToken);
      return response.data;
    } catch (e) {
      console.error("Error retrieving user settings (MainContent).");
      return [];
    }
  }

  function setupUserSettings(settings, user, idToken) {
    // toggle timeclock access
    updateUserState({ hideTimeClock: settings.NoTimeClockflag });

    // toggle department selector
    updateUserState({ hideDepartmentSelector: settings.ChangeDeptFlag });

    // set user info
    updateUserState({ email: idToken.email });
    updateUserState({ name: settings.FullName });
    updateUserState({ departmentTitle: settings.Department });

    // set department
    if (
      settings.Department !== "Air Conditioning" &&
      settings.Department !== "Plumbing" &&
      settings.Department !== "Service"
    ) {
      // not sure what to default to here, but I'll set it to Plumbing
      updateUserState({ department: "Plumbing" });
    } else {
      updateUserState({ department: settings.Department });
    }

    // setup video
    updateUserState({ bgVideoSrc: settings.Background });

    // setup theme
    if (settings.Theme === "Light") {
      updateUserState({ colorTheme: lightModeTheme });
    } else if (settings.Theme === "Dark") {
      updateUserState({ colorTheme: darkModeTheme });
    } else if (settings.Theme === "Texas") {
      updateUserState({ colorTheme: texasModeTheme });
    } else if (settings.Theme === "Test_Magenta_Theme") {
      updateUserState({ colorTheme: testMagentaTheme });
    }

    // setup logos based on theme
    if (settings.Theme === "Light" || settings.Theme === "Dark") {
      updateUserState({ appBarLogo: casaLogo });
      updateUserState({ colorLogo: casaLogoColor });
    } else if (settings.Theme === "Texas") {
      updateUserState({ appBarLogo: texasLogo });
      updateUserState({ colorLogo: texasLogoColor });
    } else {
      updateUserState({ appBarLogo: testLogo });
      updateUserState({ colorLogo: testLogo });
    }
  }

  useEffect(() => {
    setupCasa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (childRef.current) {
      // Create a new ResizeObserver instance to observe the child element
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { height } = entry.contentRect;
          setChildHeight(height);
        }
      });

      // Start observing the child element
      resizeObserver.observe(childRef.current);

      // Cleanup the observer on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [childrenWithProps]);

  return (
    <div style={{ marginTop: heightOffset }}>
      <div ref={childRef}>{childrenWithProps}</div>

      {/* Context Sensitive Help Button 

      <IconButton
        sx={{
          position: "fixed",
          bottom: 15,
          right: 15,
          zIndex: 1,
          backgroundColor: theme.palette.customColors.circleButtonColor,
          color: "#fff",
          "&:hover": {
            backgroundColor: theme.palette.customColors.circleButtonColorHover,
          },
          height: "40px",
          width: "40px",
        }}
        aria-label="add"
        href={wikiLink}
        target="_blank"
      >
        <QuestionMarkIcon
          sx={{
            height: "25px",
            width: "25px",
          }}
        />
      </IconButton> */}
    </div>
  );
};

export default MainContent;
