// ViewAssemblyTable.js
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { visuallyHidden } from "@mui/utils";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme, styled } from "@mui/material/styles";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import CasaSnackbar from "../../Resuable/CasaSnackbar/CasaSnackbar";
import "./Inventory.css"; // Import CSS for transitions
import { useInventoryContext } from "../../../contexts/InventoryContext";
import { useNavigate } from "react-router-dom";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";
import axios from "axios";
import { useAPIContext } from "../../../contexts/APIContext";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import CasaIdleTableView from "../../Resuable/CasaIdleTableView/CasaIdleTableView";
import PartLocator from "./PartLocator";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "InvDescription",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "InvPart",
    numeric: false,
    disablePadding: false,
    label: "Part #",
  },
  {
    id: "AsmblyStageX",
    numeric: false,
    disablePadding: false,
    label: "Stage",
  },
  {
    id: "InvUnit",
    numeric: false,
    disablePadding: false,
    label: "Unit",
  },
  {
    id: "AsmblyQty",
    numeric: false,
    disablePadding: false,
    label: "Qty",
  },
  {
    id: "InvCost",
    numeric: false,
    disablePadding: false,
    label: "Total",
  },
  {
    id: "InvNotes",
    numeric: false,
    disablePadding: false,
    label: "Notes",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
          {/* <span>-</span> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              "&:hover": {
                color: "#fff", // Change this to the desired hover color
              },
              "&:focus": {
                color: "#fff",
                fontWeight: "bold",
              },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                "&.MuiTableSortLabel-root": {
                  color: "white",
                },
                "&.MuiTableSortLabel-root:hover": {
                  color: "white",
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
                "&.Mui-active": {
                  color: "white",
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
                "& .MuiTableSortLabel-icon": {
                  color: "white !important",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function ThickInventoryHeader({
  name,
  rows,
  setRows,
  handleAddMode,
  setFilteredRows,
  setPage,
  backButton,
  setEditRow,
  showPartLocator,
  setShowPartLocator,
}) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { inventoryState } = useInventoryContext();

  // == FIX THIS ==
  // this needs to refresh the rows based on filter search

  React.useEffect(() => {
    const filteredRows = rows.filter((row) => {
      // Convert both fields to strings and make them lowercase for case-insensitive matching
      const valueInvPart = row["InvPart"]?.toString().toLowerCase(); // Replace 'InvPart' with the actual key
      const valueInvDescription = row["InvDescription"]
        ?.toString()
        .toLowerCase(); // Replace 'InvDescription' with the actual key

      // Check if any of the values include the search term
      const searchValue = searchTerm.toLowerCase();

      return (
        valueInvPart?.includes(searchValue) ||
        valueInvDescription?.includes(searchValue)
      );
    });

    setPage(0);
    setFilteredRows(filteredRows);
  }, [searchTerm, rows, setFilteredRows, setPage]);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // Determine screen size

  const handleSearchFilterChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const navigate = useNavigate();

  function openPartLocator() {
    // TODO:
    // FIX THIS TO FIT INSIDE OF REVIEW

    // navigate("/inventory/assembly/view/add");
    setShowPartLocator(true);
  }

  function closeButton() {
    setEditRow(null);
    backButton();
  }

  return (
    <Paper sx={{ width: "100%", mb: 2, position: "relative", padding: "2px" }}>
      <CasaCloseButton handleClose={closeButton} />
      {/* Add button for inventory - commented out b/c moving to dedicated app icon */}
      <IconButton
        sx={{
          position: "absolute",
          top: -16,
          right: -16,
          zIndex: 1,
          backgroundColor: theme.palette.customColors.circleButtonColor, // Background color of the dot
          color: "#fff", // Icon color
          "&:hover": {
            backgroundColor: theme.palette.customColors.circleButtonColorHover, // Background color on hover
          },
        }}
        aria-label="add"
        onClick={openPartLocator}
      >
        <AddIcon />
      </IconButton>
      <div
        style={{
          textAlign: isLargeScreen ? "left" : "center", // Align content based on screen size
          display: isLargeScreen ? "flex" : "block", // Use flexbox for alignment
          alignItems: "center", // Align items vertically
          justifyContent: isLargeScreen ? "space-between" : "center", // Adjust justify content based on screen size
          padding: "1vh 1vw", // Uniform padding
        }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",
            textDecoration: "underline",
            padding: "1vh",
          }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          <b>{inventoryState?.assembly?.InvDescription}</b>
        </Typography>
        {/* {isLargeScreen && ( // Render filter and search field only on large screens */}
        <>
          <TextField
            id="search-filter-textfield"
            label="Search"
            value={searchTerm}
            onChange={handleSearchFilterChange}
            sx={{
              minWidth: "15em",
              paddingTop: "0.5vh",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </>
        {isLargeScreen ? (
          <>
            <br />
            <br />
          </>
        ) : (
          <></>
        )}
        {/* )} */}
      </div>
    </Paper>
  );
}

export default function ViewAssemblyTable(props) {
  const theme = useTheme();
  const { APIState } = useAPIContext();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("InvDescription");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [editRow, setEditRow] = React.useState(null);
  const [showPartLocator, setShowPartLocator] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { inventoryState, updateInventory } = useInventoryContext();

  async function getAssemblyParts() {
    try {
      setIsLoading(true);
      const userAuthObj = await GetUserAndTokens();

      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvAssemblyItems",
          ItemID: inventoryState?.assembly?.InvID,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        setIsLoading(false);
        return [];
      } else {
        setRows(response.data);
        setIsLoading(false);
        if (inventoryState.showNewItem) {
          const newItem = response.data?.find(
            (item) => item.AsmblyPartX === inventoryState.newItem.InvID
          );
          console.log(newItem, response.data);
          setEditRow(newItem);
          updateInventory({ showNewItem: false });
        }
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      setIsLoading(false);
      return [];
    }
  }

  React.useEffect(() => {
    getAssemblyParts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleEditRow(data) {
    setEditMode(false);
    if (data) setEditRow(data);
  }

  function handleEditMode() {
    setEditMode(!editMode);
    setAddMode(false);
  }

  async function saveAssemblyItem() {
    const userAuthObj = await GetUserAndTokens();
    updateInventory({ showNewItem: true });
    try {
      const response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "SetInvAssemblyItem",
          AsmblyID: inventoryState?.assembly?.InvID,
          AsmblyPartID: editRow.AsmblyPartX,
          InvQuantity: editRow.AsmblyQty,
          InvStageID: editRow.AsmblyStageX,
          AsmblyEquipFlag: false,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log(response);
      setSnackbarOpen(true);
      handleEditRow(null);
      return response.data;
    } catch (e) {
      console.error("API Error on SetInvAssemblyItem.");
      return [];
    }
  }

  function handleSave() {
    // add POST api call here later to CasaDB
    const updatedRows = [...rows];

    for (let i = 0; i < rows.length; i++) {
      if (rows[i].AsmblyPartX === editRow.AsmblyPartX) {
        updatedRows[i].AsmblyStageX = editRow.AsmblyStageX;
        updatedRows[i].AsmblyQty = editRow.AsmblyQty;
        break;
      }
    }
    setRows(updatedRows);
    saveAssemblyItem();
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredRows, order, orderBy, page, rowsPerPage]
  );

  function handleClose() {
    setAddMode(false);
    setEditMode(false);
    setEditRow(null);
  }

  // function addModal() {
  //   return (
  //     <>
  //       <Paper
  //         sx={{
  //           width: "100%",
  //           mb: 2,
  //           padding: "1rem",
  //           position: "relative",
  //           // backgroundColor: "inherit",
  //           borderRadius: "8px",
  //           boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  //           textAlign: "center",
  //         }}
  //       >
  //         <IconButton
  //           sx={{
  //             position: "absolute",
  //             left: "0.5rem",
  //             top: "0.5rem",
  //             color: "#fff",
  //             backgroundColor:
  //               theme.palette.customColors.secondaryCircleButtonColor,
  //             "&:hover": {
  //               backgroundColor:
  //                 theme.palette.customColors.secondaryCircleButtonColorHover,
  //             },
  //           }}
  //           onClick={handleClose}
  //         >
  //           <CloseIcon />
  //         </IconButton>

  //         <span
  //           style={{
  //             fontSize: "1.5em",
  //             textDecoration: "underline",
  //             fontWeight: "bold",
  //           }}
  //         >
  //           Add Item
  //         </span>

  //         {/* <IconButton
  //           sx={{
  //             position: "absolute",
  //             top: "0.5rem",
  //             right: "0.5rem",
  //             color: "#fff",
  //             backgroundColor: theme.palette.customColors.secondaryCircleButtonColor,
  //             "&:hover": {
  //               backgroundColor: theme.palette.customColors.secondaryCircleButtonColorHover,
  //             },
  //           }}
  //           onClick={editMode ? handleSave  : handleEditMode}
  //         >
  //           {editMode ? <SaveIcon />  : <EditIcon />}
  //         </IconButton> */}
  //         <br />
  //         <br />
  //         <Grid container spacing={2}>
  //           {/* On smaller screens, display as 4x1 grid */}
  //           <Grid item xs={12} sm={6}>
  //             <TextField
  //               fullWidth
  //               id="description-textfield"
  //               label="Description"
  //             />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="category-textfield" label="Category" />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="mfg-num-textfield" label="Part #" />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="group-textfield" label="Group" />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="type-textfield" label="Type" />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="stage-textfield" label="Stage" />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="count-textfield" label="Count" />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="unit-textfield" label="Unit" />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="total-textfield" label="Cost" />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="qty-textfield" label="Qty" />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="vendor-textfield" label="Vendor" />
  //           </Grid>
  //           <Grid item xs={12} sm={6}>
  //             <TextField fullWidth id="notes-textfield" label="Notes" />
  //           </Grid>
  //         </Grid>
  //         <br />
  //         <Button variant="contained" color="primary" onClick={handleAdd}>
  //           Add
  //         </Button>
  //       </Paper>
  //     </>
  //   );
  // }

  // View Entry Row Styling
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: `5px solid ${theme.palette.customColors.tableCellOutlineColor}`,
    background: theme.palette.customColors.darkerPrimaryColor,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    padding: "16px",
  }));

  const PlainTableCell = styled(TableCell)(({ theme }) => ({
    border: `5px solid ${theme.palette.customColors.tableCellOutlineColor}`,
    // background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.customColors.lighterPrimaryColor} 100%)`,
    // color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    padding: "16px",
    "&:first-of-type": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    "&:last-of-type": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  }));

  const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
    // padding: "2px",
    // borderRadius: "8px",
    // boxShadow: "2px 3px 5px 2px rgba(255, 105, 135, .3)",
  }));

  const TableStyled = styled(Table)(({ theme }) => ({
    // margin: "auto",
    // minWidth: 650,
    // borderCollapse: "separate",
    // borderSpacing: "0 10px",
  }));

  function handleCountChange(value, field) {
    // Ensure that value is a number and handle empty string case
    if (value === "") {
      value = 0; // Default to 0 if input is empty
    }

    // Create a new object to avoid mutating the original state
    const newRow = { ...editRow };

    if (field === "stage") {
      newRow.AsmblyStageX = value;
    } else if (field === "qty") {
      newRow.AsmblyQty = value;
    }

    // Update the state with the new object
    setEditRow(newRow);
  }

  function editModeView() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" sx={{ width: 300 }}>
            <InputLabel id="stage-select-label">Stage</InputLabel>
            <Select
              fullWidth
              id="stage-select"
              label="Stage"
              value={editRow.AsmblyStageX || ""} // Ensure that the correct value is selected
              onChange={(e) => {
                const value = e.target.value;
                handleCountChange(value, "stage"); // Pass the StageID to handleCountChange
              }}
              variant="outlined"
            >
              {inventoryState.stageOptions?.map((stage) => (
                <MenuItem key={stage.StageID} value={stage.StageID}>
                  {stage.StageDesc} {/* Display StageDesc as the label */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            id="qty-textfield"
            label="Qty"
            value={editRow.AsmblyQty}
            onChange={(e) => {
              let value = e.target.value;

              // Allow "0" as a valid input on its own
              if (value === "0") {
                handleCountChange(value, "qty");
              } else {
                // Remove leading zeros for all other cases
                value = value.replace(/^0+/, "");

                // Ensure the value is numeric
                if (/^\d*$/.test(value)) {
                  handleCountChange(value, "qty");
                }
              }
            }}
            variant="outlined"
            sx={{
              width: 300,
              "& input": {
                textAlign: "center",
              },
              "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
            }}
          />
        </Grid>
      </Grid>
    );
  }

  const [removeConfirmOpen, setRemoveConfirmOpen] = React.useState(false);

  async function deleteItem() {
    try {
      const userAuthObj = await GetUserAndTokens();

      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "DeleteInvAssemblyItem",
          AsmblyID: inventoryState?.assembly?.InvID,
          AsmblyPartID: editRow.AsmblyPartX,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });

      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API. ", response);
        return [];
      } else {
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      return [];
    }
  }

  function handleRemoveItem() {
    deleteItem();
    let indexToRemove = rows.findIndex(
      (item) => item.AsmblyPartX === editRow.AsmblyPartX
    );
    if (indexToRemove > -1) {
      let rowsCopy = rows;
      rowsCopy.splice(indexToRemove, 1);
      setRows(rowsCopy);
    }
    setAddMode(false);
    setEditMode(false);
    setEditRow(null);
    setRemoveConfirmOpen(false);
  }

  const handleRemoveConfirmOpen = () => {
    setRemoveConfirmOpen(true);
  };

  const handleRemoveConfirmClose = () => {
    setRemoveConfirmOpen(false);
  };

  function viewModeView() {
    return (
      <>
        <TableContainerStyled>
          <TableStyled>
            <TableBody>
              <TableRow>
                <StyledTableCell>
                  <Typography>
                    <b>Description</b>
                  </Typography>
                </StyledTableCell>
                <PlainTableCell>
                  <Typography>{editRow.InvDescription}</Typography>
                </PlainTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography>
                    <b>Part #</b>
                  </Typography>
                </StyledTableCell>
                <PlainTableCell>
                  <Typography>{editRow.InvPart}</Typography>
                </PlainTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography>
                    <b>Stage</b>
                  </Typography>
                </StyledTableCell>
                <PlainTableCell>
                  <Typography>
                    {
                      inventoryState.stageOptions?.find(
                        (stage) => stage.StageID === editRow.AsmblyStageX
                      )?.StageDesc
                    }
                  </Typography>
                </PlainTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography>
                    <b>Unit</b>
                  </Typography>
                </StyledTableCell>
                <PlainTableCell>
                  <Typography>{editRow.InvUnit}</Typography>
                </PlainTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography>
                    <b>Qty</b>
                  </Typography>
                </StyledTableCell>
                <PlainTableCell>
                  <Typography>{editRow.AsmblyQty}</Typography>
                </PlainTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography>
                    <b>Total</b>
                  </Typography>
                </StyledTableCell>
                <PlainTableCell>
                  <Typography>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(editRow.InvCost * editRow.AsmblyQty)}
                  </Typography>
                </PlainTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography>
                    <b>Notes</b>
                  </Typography>
                </StyledTableCell>
                <PlainTableCell>
                  <Typography>{editRow.InvNotes}</Typography>
                </PlainTableCell>
              </TableRow>
            </TableBody>
          </TableStyled>
        </TableContainerStyled>
        <br />
        <Button
          type="submit"
          variant="contained"
          style={{ backgroundColor: "#d32f2f" }}
          onClick={handleRemoveConfirmOpen}
        >
          Remove Item
        </Button>

        <Dialog
          open={removeConfirmOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Remove Item</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will remove "
              <b>
                <u>{editRow.InvDescription}</u>
              </b>
              " from the assembly.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleRemoveConfirmClose}
              sx={{ color: theme.palette.primary.main }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleRemoveItem}
              sx={{ color: theme.palette.primary.main }}
              autoFocus
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function editModal() {
    if (editRow) {
      return (
        <>
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              padding: "1rem",
              position: "relative",
              // backgroundColor: "inherit",
              borderRadius: "8px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                left: "-0.75rem",
                top: "-0.75rem",
                border: "1px solid #fff",
                color: "#fff",
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColor,
                "&:hover": {
                  backgroundColor:
                    theme.palette.customColors.secondaryCircleButtonColorHover,
                },
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>

            <span
              style={{
                fontSize: "1.5em",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              {editMode ? "Edit Item" : "View Item"}
            </span>

            <IconButton
              sx={{
                position: "absolute",
                top: "-0.75rem",
                right: "-0.75rem",
                border: "1px solid #fff",
                color: "#fff",
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColor,
                "&:hover": {
                  backgroundColor:
                    theme.palette.customColors.secondaryCircleButtonColorHover,
                },
              }}
              onClick={editMode ? handleSave : handleEditMode}
            >
              {editMode ? <SaveIcon /> : <EditIcon />}
            </IconButton>
            <br />
            <br />
            <div
              style={{
                display: "inline-block", // Allow the width to adapt to the children
                maxWidth: "100%", // Optional: Set a maximum width if you don't want it too wide
              }}
            >
              {editMode ? editModeView() : viewModeView()}
            </div>
          </Paper>
        </>
      );
    } else {
      return null;
    }
  }

  const tableContainerRef = React.useRef(null);
  React.useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [page]);

  function mainTable(tableContainerRef) {
    return (
      <>
        <ThickInventoryHeader
          name={props.name}
          rows={rows}
          setRows={setRows}
          // handleAddMode={handleAddMode}
          setFilteredRows={setFilteredRows}
          setPage={setPage}
          backButton={props.backButton}
          setEditRow={setEditRow}
          showPartLocator={showPartLocator}
          setShowPartLocator={setShowPartLocator}
        />

        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer
              ref={tableContainerRef}
              sx={{ minHeight: "60vh", maxHeight: "60vh" }}
            >
              {!isLoading && rows.length > 0 ? (
                <Table
                  stickyHeader
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => handleEditRow(row)}
                        >
                          <TableCell onClick={(e) => handleEditRow(row)}>
                            {/* <InfoOutlinedIcon sx={{ color: "#555" }} /> */}
                          </TableCell>
                          <TableCell style={{ minWidth: "235px" }}>
                            {row.InvDescription}
                          </TableCell>
                          <TableCell>{row.InvPart}</TableCell>
                          <TableCell>
                            {
                              inventoryState.stageOptions?.find(
                                (stage) => stage.StageID === row.AsmblyStageX
                              )?.StageDesc
                            }
                          </TableCell>
                          <TableCell>{row.InvUnit}</TableCell>
                          <TableCell>{row.AsmblyQty}</TableCell>
                          <TableCell>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(row.InvCost * row.AsmblyQty)}
                          </TableCell>
                          <TableCell>{row.InvNotes}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <CasaIdleTableView
                  isLoading={isLoading}
                  noResultText="No results. Use the dropdowns to select a category and group."
                />
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </>
    );
  }

  const getKey = () => {
    // if (addMode) return 0; // Assuming index 0 is for addModal
    if (editRow) return 0; // Assuming index 1 is for editModal
    if (showPartLocator) return 2;
    return 1; // Assuming index 2 is for mainTable
  };

  const compArr = [
    // <div key="addModal">{addModal()}</div>,
    <div key="editModal">{editModal()}</div>,
    <div key="mainTable">{mainTable(tableContainerRef)}</div>,
    <div key="partLocator">
      <PartLocator
        name={props.title}
        backButton={() => {
          setShowPartLocator(false);
        }}
        rows={rows}
        setRows={setRows}
      />
    </div>,
  ];

  return (
    <div>
      <CasaSnackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={"Saved successfully."}
      />
      <SwitchTransition>
        <CSSTransition
          key={getKey()}
          timeout={150}
          classNames="fade-inventory"
          unmountOnExit
        >
          {compArr[getKey()]}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}
