// Home.js
import React from "react";
import CasaAppFolder from "../../Resuable/CasaAppFolder/CasaAppFolder";

const Home = (props) => {
  const iconsData = [
    {
      title: "Messages",
      color: "green",
      altColor: "olive",
      linkto: "/messages",
    },
    {
      title: "Inventory",
      color: "blue",
      altColor: "purple",
      linkto: "/inventory/",
    },
    {
      title: "Time Clock",
      color: "red",
      altColor: "orange",
      linkto: "/timeclock",
    },
    {
      title: "Administration",
      color: "black",
      altColor: "brown",
      linkto: "/administration",
    },
    {
      title: "Purchasing",
      color: "#0b210d",
      altColor: "#0a2e19",
      linkto: "/purchasing",
    },
    {
      title: "Settings",
      color: "#333333",
      altColor: "#808080",
      linkto: "/settings",
    },
    {
      title: "Updates",
      color: "#00999c",
      altColor: "#006946",
      linkto: "/updates",
    },
    {
      title: "CasaGPT",
      color: "green",
      altColor: "green",
      linkto: "/casagpt",
    },
  ];

  return <CasaAppFolder title="Home" iconsData={iconsData} />;
};

export default Home;
